<!--
 * @Description: 第三步 设施和服务
 * @Author: 琢磨先生
 * @Date: 2022-05-25 08:53:09
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-09 21:10:43
-->

<template>
  <el-scrollbar>
    <el-checkbox-group v-model="form.facility_ids">
      <el-form :model="form" ref="form" :rules="rules" label-position="top">
        <el-form-item
          :label="item.name"
          v-for="item in facilities"
          :key="item.id"
        >
          <el-checkbox
            :label="facility.id"
            v-for="facility in item.children"
            :key="facility.id"
            >{{ facility.name }}</el-checkbox
          >
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="saving"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
    </el-checkbox-group>
  </el-scrollbar>
</template>

<script>
import common_api from "@/http/common_api";

export default {
  data() {
    return {
      loading: false,
      saving: false,
      //设施
      facilities: [],
      form: {},
      rules: {},
    };
  },
  emits: ["change"],
  inject: ["common"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_facility().then((res) => {
      if (res.code == 0) {
        this.facilities = res.data;
      }
    });
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);

          if (this.form.facility_ids.length == 0) {
            this.$message({
              type: "warning",
              message: "请选择设施",
            });
            return;
          }

          this.saving = true;
          this.$http
            .post("seller/v1/house/edit/facility", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("change", "facility");
              }
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>