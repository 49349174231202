<!--
 * @Description: 新增、修改房源
 * @Author: 琢磨先生
 * @Date: 2022-05-24 23:13:55
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-06 17:17:30
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    size="1100px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="house-drawer"
    @closed="onClose"
    :destroy-on-close="true"
  >
    <el-tabs v-model="activeName" v-loading="loading">
      <el-tab-pane name="location">
        <template #label>
          <span class="flex-row item-center">
            <span class="tab-title">房源位置</span>
            <el-icon
              v-if="form.steps.findIndex((o) => o == 'location') > -1"
              color="green"
              size="18px"
              ><Select
            /></el-icon>
          </span>
        </template>
        <step-location
          :item="form"
          :area_tree="treeAreas"
          :areas="areas"
          @change="onSaveChange"
          v-if="activeName == 'location'"
        ></step-location>
      </el-tab-pane>
      <el-tab-pane :disabled="!form.id" name="info">
        <template #label>
          <span class="flex-row item-center">
            <span class="tab-title">房源概况</span>
            <el-icon
              v-if="form.steps.findIndex((o) => o == 'info') > -1"
              color="green"
              size="18px"
              ><Select
            /></el-icon>
          </span>
        </template>
        <step-info
          :item="form"
          @change="onSaveChange"
          v-if="activeName == 'info'"
        ></step-info>
      </el-tab-pane>
      <el-tab-pane :disabled="!form.id" name="facility">
        <template #label>
          <span class="flex-row item-center">
            <span class="tab-title">设施和服务</span>
            <el-icon
              v-if="form.steps.findIndex((o) => o == 'facility') > -1"
              color="green"
              size="18px"
              ><Select
            /></el-icon>
          </span>
        </template>
        <step-facility
          :item="form"
          @change="onSaveChange"
          v-if="activeName == 'facility'"
        ></step-facility>
      </el-tab-pane>
      <el-tab-pane :disabled="!form.id" name="desc">
        <template #label>
          <span class="flex-row item-center">
            <span class="tab-title">房源描述</span>
            <el-icon
              v-if="form.steps.findIndex((o) => o == 'desc') > -1"
              color="green"
              size="18px"
              ><Select
            /></el-icon>
          </span>
        </template>
        <step-desc
          :item="form"
          @change="onSaveChange"
          v-if="activeName == 'desc'"
        ></step-desc>
      </el-tab-pane>
      <el-tab-pane :disabled="!form.id" name="image">
        <template #label>
          <span class="flex-row item-center">
            <span class="tab-title">房源照片</span>
            <el-icon
              v-if="form.steps.findIndex((o) => o == 'image') > -1"
              color="green"
              size="18px"
              ><Select
            /></el-icon>
          </span>
        </template>
        <step-image
          :item="form"
          @change="onSaveChange"
          v-if="activeName == 'image'"
        ></step-image>
      </el-tab-pane>
      <el-tab-pane :disabled="!form.id" name="price">
        <template #label>
          <span class="flex-row item-center">
            <span class="tab-title">价格和预定</span>
            <el-icon
              v-if="form.steps.findIndex((o) => o == 'price') > -1"
              color="green"
              size="18px"
              ><Select
            /></el-icon>
          </span>
        </template>
        <step-price
          :item="form"
          @change="onSaveChange"
          v-if="activeName == 'price'"
        ></step-price>
      </el-tab-pane>
      <el-tab-pane :disabled="!form.id" name="askfor">
        <template #label>
          <span class="flex-row item-center">
            <span class="tab-title">入住要求</span>
            <el-icon
              v-if="form.steps.findIndex((o) => o == 'askfor') > -1"
              color="green"
              size="18px"
              ><Select
            /></el-icon>
          </span>
        </template>
        <step-askfor
          :item="form"
          @change="onSaveChange"
          v-if="activeName == 'askfor'"
        ></step-askfor>
      </el-tab-pane>
      <el-tab-pane :disabled="!form.id" name="store">
        <template #label>
          <span class="flex-row item-center">
            <span class="tab-title">房间设置</span>
            <el-icon
              v-if="form.steps.findIndex((o) => o == 'store') > -1"
              color="green"
              size="18px"
              ><Select
            /></el-icon>
          </span>
        </template>
        <step-store
          :item="form"
          @change="onSaveChange"
          v-if="activeName == 'store'"
        ></step-store>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
</template>

<script>
import common_api from "@/http/common_api";
import StepLocation from "./step_location.vue";
import StepInfo from "./step_info.vue";
import StepDesc from "./step_desc.vue";
import StepFacility from "./step_facility.vue";
import StepImage from "./step_image.vue";
import StepPrice from "./step_price.vue";
import StepStore from "./step_store.vue";
import StepAskfor from "./step_askfor.vue";

export default {
  components: {
    StepLocation,
    StepInfo,
    StepDesc,
    StepFacility,
    StepPrice,
    StepStore,
    StepImage,
    StepAskfor,
  },
  data() {
    return {
      loading: false,
      drawer: false,
      title: "新增房源",
      color: "#a8abb2",
      activeName: "location",
      form: {
        steps: [],
      },
      //区域数据
      // area_tree: [],
      treeAreas: [],
      areas: [],
      //指示是否更改
      is_update: false,
    };
  },
  emits: ["close"],
  props: ["item", "area_tree", "buildings"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.is_update = false;
          if (this.item.id) {
            this.activeName = "location";
            this.id = this.item.id;
            this.loadDtl();
          }
        } else {
          this.form = {
            steps: [],
          };
        }
      },
      immediate: true,
    },
    area_tree: {
      handler() {
        this.treeAreas = this.area_tree;
      },
    },
  },
  created() {
    //区域
    common_api.get_area().then((res) => {
      if (res.code == 0) {
        this.areas = res.data;
      }
    });
  },
  methods: {
    /**
     * 加载详情
     */
    loadDtl() {
      this.loading = true;
      this.$http.get("seller/v1/house/dtl?id=" + this.id).then((res) => {
        if (res.code == 0) {
          this.form = res.data;
          this.form.steps = this.form.step_ok.split(",");
        }
        this.loading = false;
      });
    },
    /**
     * 关闭
     */
    onClose() {
      // this.drawer = false;
      this.$emit("close", this.is_update);
    },
    /**
     * 保存更新
     */
    onSaveChange(step, data) {
      if (data) {
        this.id = data.id;
      }
      this.loadDtl();
      if (step == "location") {
        this.activeName = "info";
      }
      if (step == "info") {
        this.is_update = true;
        this.activeName = "facility";
      }
      if (step == "facility") {
        this.activeName = "desc";
      }
      if (step == "desc") {
        this.is_update = true;
        this.activeName = "image";
      }
      if (step == "image") {
        this.activeName = "price";
      }
      if (step == "price") {
        this.activeName = "askfor";
      }
      if (step == "askfor") {
        this.activeName = "store";
      }
    },
  },
};
</script>

<style >
/* .house-drawer .el-drawer__body {
  padding-top: 0;
} */

.house-drawer .el-tabs .tab-title {
  margin-right: 5px;
}

.house-drawer .el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.house-drawer .el-tabs__content {
  flex-grow: 1;
  height: 1px;
}

.house-drawer .el-tab-pane {
  height: 100%;
}

.house-drawer .el-form-item {
  margin-bottom: 30px;
}
</style>














