<!--
 * @Description:第一步 创建房源或修改房源位置信息
 * @Author: 琢磨先生
 * @Date: 2022-05-25 08:52:04
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-09 10:48:03
-->
<template>
  <el-scrollbar>
    <el-form :model="form" ref="form" :rules="rules" label-position="top">
      <el-form-item label="门店" prop="shop_id">
        <el-select v-model="form.shop_id" placeholder>
          <el-option
            v-for="item in shop_list"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <div class="help-block">门店更改后房源自动下架且关联房间将会移除，请重新关联后再上架。</div>
      </el-form-item>
      <el-form-item label="城市/区域" prop="areaIds">
        <el-col :span="10">
          <el-cascader
            :options="common.area_tree"
            v-model="form.areaIds"
            :props="{ label: 'name', value: 'id', expandTrigger: 'hover' }"
            @change="areaChange"
            style="width:100%"
          ></el-cascader>
        </el-col>
      </el-form-item>

      <el-form-item label="街道/小区" prop="address">
        <el-col :span="15">
          <el-input v-model="form.address" placeholder="请输入街道、小区地址"></el-input>
        </el-col>
      </el-form-item>
      <!-- <el-form-item label="门牌号" prop="house_no">
        <el-col :span="8">
          <el-input
            v-model="form.house_no"
            placeholder="请输入楼/单元/门牌号"
          ></el-input>
        </el-col>
        <div class="help-block">此信息仅在房客成功预订后可见，请详细填写</div>
      </el-form-item>-->

      <el-form-item label="位置标注" prop="lng">
        <el-col :span="22">
          <div style="height: 450px; width: 100%">
            <div class="map-body">
              <div id="map" style="height: 100%"></div>
              <div id="search">
                <el-input
                  id="keyword"
                  v-model="q"
                  placeholder="请输入关键字搜索"
                  size="large"
                  prefix-icon="Search"
                />
              </div>
            </div>
          </div>
        </el-col>
        <div class="help-block">请在地图中搜索相应位置并选择标注地点或点击地图手动标注</div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving">下一步</el-button>
      </el-form-item>
    </el-form>
  </el-scrollbar>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import shop_api from "../../../http/shop_api";
export default {
  components: {},
  data() {
    return {
      saving: false,
      form: {},
      rules: {
        shop_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        areaIds: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "地址必填",
            trigger: "blur",
          },
        ],
        house_no: [
          {
            required: true,
            message: "门牌号必填",
            trigger: "blur",
          },
        ],
        lng: [
          {
            required: true,
            message: "地理位置必填",
            trigger: "change",
          },
        ],
      },
      //地图搜索关键字
      q: "",
      //当前选择的城市
      area: null,
      //门店列表
      shop_list:[],
    };
  },
  setup() {
    return {
      map: null,
      auto: null,
      AMap: null,
    };
  },
  props: ["item", "areas"],
  inject: ["common"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
          this.form.areaIds = [];
          if (this.item.id) {
            this.recursion(this.form.area_id);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    //配置地图SecretKey
    this.settings = this.$store.getters.getSettings;
    window._AMapSecurityConfig = {
      securityJsCode: this.settings.amapSecretKey,
    };

    //
    shop_api.get_my_shops().then((res) => {
      if (res.code == 0) {
        this.shop_list = res.data;
      }
    });
  },
  mounted() {
    this.createMap();
  },
  emits: ["change"],
  methods: {
    /**
     * 递归获取区域id集合
     */
    recursion(area_id) {
      this.form.areaIds.unshift(area_id);
      var area = this.areas.find((o) => o.id == area_id);
      if (area) {
        if (area.father_id) {
          this.recursion(area.father_id);
        }
      }
    },
    /**
     * 创建地图
     */
    createMap() {
      AMapLoader.load({
        key: this.settings.amapWebAppKey,
        version: "2.0",
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Marker",
          "PlaceSearchRender",
        ],
        AMapUI: {
          version: "1.1",
          plugins: [],
        },
        Loca: {
          version: "2.0",
        },
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("map", {
            zoom: 16,
            // center:
            //   this.area.lng != null && this.area.lat != null
            //     ? [this.area.lng, this.area.lat]
            //     : null,
            viewMode: "3D",
            resizeEnable: true,
          });
          //输入提示
          var autoOptions = {
            city: this.area != null ? this.area.adcode : "",
            input: "keyword",
            citylimit: true,
          };
          this.auto = new AMap.AutoComplete(autoOptions);
          this.auto.on("select", (e) => {
            this.createMarker(e.poi.location);
          });

          //点击地图获取当前位置坐标
          this.map.on("click", (e) => {
            this.createMarker(e.lnglat);
          });
          //修改时默认显示标注点
          if (this.form.id && this.form.lng) {
            this.createMarker(new AMap.LngLat(this.form.lng, this.form.lat));
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
     * 创建显示标记
     */
    createMarker(position) {
      console.log(position);
      this.map.clearMap();
      var marker = new this.AMap.Marker({
        position: position,
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        offset: new this.AMap.Pixel(-13, -30),
      });
      this.map.add(marker);
      this.map.setFitView(marker);
      this.map.setZoom(20);
      //设置结果
      this.form.lng = position.lng;
      this.form.lat = position.lat;
    },
    /**
     * 城市选择更改
     */
    areaChange(values) {
      var areaId = values[values.length - 1];
      this.form.area_id = areaId;
      var area = this.areas.find((o) => o.id == areaId);
      if (area.lng && area.lat) {
        this.map.setCenter([area.lng, area.lat]);
      }
      //设置搜索的城市
      this.auto.setCity(area.adcode.substring(0, 4) + "00");
      this.form.lng = null;
      this.form.lat = null;
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);
          this.saving = true;
          this.$http
            .post("seller/v1/house/edit/location", this.form)
            .then((res) => {
              if (res.code == 0) {
                console.log(res);
                this.$emit("change", "location", res.data);
              }
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style >
.map-body {
  height: 100%;
  position: relative;
}
#search {
  position: absolute;
  top: 10px;
  z-index: 2;
  width: 500px;
  left: 50%;
  margin-left: -250px;
}
.amap-sug-result {
  z-index: 9999 !important;
}
.amap-sug-result .auto-item {
  padding: 10px;
}
</style>