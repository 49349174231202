<!--
 * @Description: 第七步（最后一步） 库存
 * @Author: 琢磨先生
 * @Date: 2022-05-25 08:54:59
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-09 11:16:14
-->

<template>
  <el-scrollbar>
    <!-- <el-form>  -->
    <!-- <el-form-item label> -->
    <el-button round icon="Paperclip" @click="onLinkStore">关联房间</el-button>
    <!-- </el-form-item> -->
    <div class="stores">
      <el-card shadow="hover" v-for="store in item.stores" :key="store.id">
        <p class="name">
          <span>{{ store.house_no }} </span>
          <el-tag type="danger" v-if="store.is_stop" size="small">停用</el-tag>
        </p>
        <div class="landlord">业主：{{ store.landlord.name }}</div>
        <div class="landlord">{{ store.huxing.name }}</div>
        <!-- <div class="income_rate">
          分成占比：{{ store.income.decimal_income_rate }}%
        </div> -->
        <!-- <div class="actions">
          <el-button type="primary" link size="small" @click="editStore(store)"
            >修改</el-button
          >
          <el-popconfirm title="您确定要移除关联？" @confirm="onDelete(store)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >移除</el-button
              >
            </template>
          </el-popconfirm>
        </div> -->
      </el-card>
    </div>
    <!-- </el-form> -->
  </el-scrollbar>

  <el-drawer
    title="关联房间"
    v-model="dialogVisible"
    size="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-checkbox-group v-model="form.storeIds">
      <el-table :data="store_list" border>
        <el-table-column label="房型" prop="name" width="200"></el-table-column>
        <el-table-column label="房间号">
          <template #default="scope">
            <el-checkbox
              :label="item.id"
              v-for="item in scope.row.store_list"
              :key="item.id"
              >{{ item.house_no }}</el-checkbox
            >
          </template>
        </el-table-column>
      </el-table>
    </el-checkbox-group>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >确定</el-button
        >
      </span>
    </template>
  </el-drawer>
</template>

<script>
// import common_api from "@/http/common_api";
// import expense_api from "@/http/expense_api";
// import huxing_api from "@/http/huxing_api";
// import LandlordChoose from "@/views/components/landlord_choose";
export default {
  components: {
    // LandlordChoose
  },
  data() {
    return {
      loading: false,
      saving: false,
      dialogVisible: false,
      title: "",
      //房间列表
      store_list: [],
      form: {},
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.loadStores();
          if (this.item.stores) {
            this.form.storeIds = [];
            this.item.stores.forEach((x) => {
              this.form.storeIds.push(x.id);
            });
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    // this.loadStores();
  },
  methods: {
    /**
     * 获取所有的户型房间数据
     */
    loadStores() {
      this.checkedStores = [];
      this.$http
        .get("seller/v1/house/huXing_stores?shopId="+this.item.shop_id)
        .then((res) => {
          if (res.code == 0) {
            this.store_list = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 关联房间
     */
    onLinkStore() {
      this.dialogVisible = true;
    },

    // /**
    //  * 移除关联
    //  */
    // onDelete(item) {
    //   this.$http.get("seller/v1/house/del-store?id=" + item.id).then((res) => {
    //     if (res.code == 0) {
    //       this.$message({
    //         type: "success",
    //         message: res.msg,
    //       });
    //       this.$emit("change", "store");
    //     }
    //   });
    // },

    /**
     * 保存库存数据
     */
    onSubmit() {
      this.form.house_id = this.item.id;
      this.$http.post("seller/v1/house/linkStore", this.form).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.$emit("change", "store");
        }
        this.saving = false;
        this.dialogVisible = false;
      });
    },
  },
};
</script>

<style scoped>
.stores {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}
.stores .el-card {
  min-width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.stores .name {
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  display: flex;
  align-items: center;
}
.stores .landlord {
  color: #999;
  font-size: 14px;
  margin-top: 5px;
}
.stores .actions {
  line-height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.income_rate {
  color: #999;
  font-size: 14px;
  margin-top: 5px;
}
</style>