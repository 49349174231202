<!--
 * @Description: 第二步 编辑房源概况
 * @Author: 琢磨先生
 * @Date: 2022-05-25 08:52:38
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-07 00:45:06
-->
<template>
  <el-scrollbar>
    <el-form :model="form" ref="form" :rules="rules" label-position="top">
      <el-form-item label="封面" prop="logo_url">
        <!-- <upload-avatar
          :url="form.logo_url"
          @change="uploadChange"
        ></upload-avatar> -->
        <!-- <div class="image">
            <el-image
              :src="form.logo_url"
              :preview-src-list="[form.logo_url]"
              :initial-index="0"
              fit="scale-down"
            ></el-image>
          </div> -->
        <image-space
          :aspectRatio="4 / 3"
          :src="form.logo_url"
          @change="chooseChange"
        ></image-space>
        <div class="help-block">
          图片要求比例为4:3的图片，且图片大小不能超过5M
        </div>
      </el-form-item>

      <el-form-item label="售卖类型">
        <el-radio-group v-model="form.type">
          <el-radio :label="0">整套</el-radio>
          <el-radio :label="1">单间</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="房源类型" prop="buildingIds">
        <el-cascader
          :options="common.buildings"
          v-model="form.buildingIds"
          :props="{
            expandTrigger: 'hover',
            label: 'name',
            value: 'id',
          }"
          @change="buildingChange"
        >
        </el-cascader>
      </el-form-item>

      <!-- <el-form-item label="门店">
        <el-select v-model="form.shop_id" placeholder="" clearable>
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in stopList"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="面积(㎡)" prop="acreage">
        <el-col :span="5">
          <el-input
            v-model="form.acreage"
            placeholder="请输入房屋面积"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-row>
        <el-col :span="4">
          <el-form-item label="卧室" prop="bedroom">
            <el-select
              class="room"
              v-model="form.bedroom"
              placeholder=""
              @change="bedroomChange"
            >
              <el-option
                :label="i"
                :value="i"
                v-for="i in 20"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="客厅" prop="hall">
            <el-select class="room" v-model="form.hall" placeholder="">
              <el-option
                :label="i - 1"
                :value="i - 1"
                v-for="i in 21"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="厨房" prop="kitchen">
            <el-select v-model="form.kitchen" class="room" placeholder="">
              <el-option
                :label="i - 1"
                :value="i - 1"
                v-for="i in 21"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="卫生间" prop="bathroom">
            <el-select v-model="form.bathroom" class="room" placeholder="">
              <el-option
                :label="i - 1"
                :value="i - 1"
                v-for="i in 21"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="阳台" class="room" prop="balcony">
            <el-select v-model="form.balcony" placeholder="">
              <el-option
                :label="i - 1"
                :value="i - 1"
                v-for="i in 21"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
        :label="'卧室' + (item.room_num + 1)"
        v-for="item in form.bed_groups"
        :key="item.room_num"
      >
        <el-col
          :span="8"
          placeholder=""
          v-for="(bed, index) in item.beds"
          :key="bed.id"
        >
          <el-select style="margin-bottom: 10px" v-model="bed.bed_id">
            <el-option
              :label="x.name"
              :value="x.id"
              v-for="x in bedList"
              :key="x.id"
            ></el-option>
          </el-select>
          <el-button
            type="text"
            class="btn-del"
            size="small"
            style="margin-left: 10px"
            v-if="index != 0"
            @click="delBed(item, index)"
            >删除</el-button
          >
        </el-col>
        <div class="help-block">
          <el-button
            type="default"
            size="small"
            icon="plus"
            @click="addBed(item)"
            >添加床型</el-button
          >
        </div>
      </el-form-item>

      <el-form-item label="宜住人数" prop="person_sum">
        <el-col :span="5">
          <el-input v-model="form.person_sum" placeholder="请输入"></el-input>
        </el-col>
        <div class="help-block">不可超过所有床型可住人数上限</div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </el-scrollbar>
</template>

<script>
import common_api from "@/http/common_api";
// import UploadAvatar from "@/views/upload/upload_avatar.vue";
import ImageSpace from "../../psd/single_upload.vue";

export default {
  components: {
    // UploadAvatar,
    ImageSpace,
  },
  data() {
    return {
      loading: false,
      saving: false,
      //床型
      bedList: [],
      //门店
      stopList: [],
      form: {},
      rules: {
        logo_url: [
          {
            required: true,
            message: "请上传封面图片",
            trigger: "blur",
          },
        ],
        buildingIds: [
          {
            required: true,
            message: "房源类型必选",
            trigger: "blur",
          },
        ],
        acreage: [
          {
            required: true,
            message: "面积必填",
            trigger: "blur",
          },
        ],
        landlord_id: [
          {
            required: true,
            message: "房东必选",
            trigger: "blur",
          },
        ],
        bedroom: [
          {
            required: true,
            message: "卧室必选",
            trigger: "blur",
          },
        ],
        person_sum: [
          {
            required: true,
            message: "宜住人数必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  emits: ["change"],
  inject: ["common"],
  props: ["item"],
  created() {
    common_api.get_beds().then((res) => {
      if (res.code == 0) {
        this.bedList = res.data;
      }
    });
    //门店
    common_api.get_stops().then((res) => {
      if (res.code == 0) {
        this.stopList = res.data;
      }
    });
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);
          if (this.form.bed_groups.length != this.form.bedroom) {
            //房间和床型数据是否相等
            this.$message({
              type: "warning",
              message: "床型数据错误",
            });
            return;
          }
          var array = [];
          this.form.bed_groups.forEach((room) => {
            room.beds.forEach((bed) => {
              bed.room_num = room.room_num;
              array.push(bed);
            });
          });
          if (array.findIndex((o) => o.bed_id == "") > -1) {
            this.$message({
              type: "warning",
              message: "存在未选择的房间床型、请选择",
            });
            return;
          }
          //赋值床型列表数据
          this.form.beds = array;
          this.saving = true;
          this.$http
            .post("seller/v1/house/edit/info", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("change", "info");
              }
              this.saving = false;
            });
        }
      });
    },
    /**
     * 图片上传回调
     * @param {*} url
     */
    chooseChange(url) {
      console.log(url);
      this.form.logo_url = url;
    },
    /**
     * 房屋类型选择更改
     */
    buildingChange(values) {
      if (values) {
        this.form.building_id = values[values.length - 1];
      } else {
        this.form.building_id = null;
      }
    },

    /**
     * 房间选择更改
     * @param {*} val
     */
    bedroomChange(val) {
      this.form.bed_groups = [];
      for (let i = 0; i < val; i++) {
        this.form.bed_groups.push({
          room_num: i,
          beds: [
            {
              bed_id: "",
            },
          ],
        });
      }
    },
    /**
     * 添加床型
     * @param {*} item
     */
    addBed(item) {
      item.beds.push({
        bed_id: "",
      });
    },
    delBed(item, i) {
      console.log(i);
      item.beds.splice(i, 1);
    },
  },
};
</script>

<style   scoped>
.pic_list {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}
.pic_list .tip {
  width: 100%;
}
.pic_list .image {
  margin: 0 10px 10px 0;
  width: 160px;
  height: 120px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pic_list .image .el-image {
  border-radius: 5px;
}

.el-col .el-select.room {
  width: 150px;
}
</style>