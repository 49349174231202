<!--
 * @Description: 第四步 房源描述
 * @Author: 琢磨先生
 * @Date: 2022-05-25 08:53:36
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-26 14:59:38
-->

<template>
  <el-scrollbar>
    <el-form :model="form" ref="form" :rules="rules" label-position="top">
      <el-form-item label="标题" prop="name">
        <el-col :span="16"> <el-input v-model="form.name"></el-input></el-col>
        <div class="help-block">对外展示标题名称</div>
      </el-form-item>

      <el-form-item label="内部名称" prop="private_name">
        <el-col :span="12">
          <el-input v-model="form.private_name"></el-input
        ></el-col>
        <div class="help-block">内部名称，例如xx小区101房</div>
      </el-form-item>

      <el-form-item label="房屋介绍">
        <el-col :span="18">
          <el-input
            v-model="form.content"
            type="textarea"
            rows="6"
            placeholder=""
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="出行及周边描述">
        <el-col :span="18">
          <el-input
            v-model="form.trip_around"
            type="textarea"
            placeholder=""
            rows="6"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </el-scrollbar>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      saving: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "标题必填",
            trigger: "blur",
          },
        ],
        private_name: [
          {
            required: true,
            message: "内部名称必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);

          this.saving = true;
          this.$http
            .post("seller/v1/house/edit/desc", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("change", "desc");
              }
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>