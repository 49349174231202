<!--
 * @Description: 第六步 价格和预定
 * @Author: 琢磨先生
 * @Date: 2022-05-25 08:54:38
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-23 12:49:13
-->
<template>
  <el-scrollbar>
    <el-form :model="form" ref="form" :rules="rules" label-position="top">
      <el-form-item label="平日价" prop="normal_price">
        <el-col :span="5">
          <el-input
            v-model="form.decimal_normal_price"
            placeholder="请输入10-99999元"
          ></el-input
        ></el-col>
        <div class="help-block">平日价周日~周四（包含其他工作日）</div>
      </el-form-item>
      <el-form-item label="周末价" prop="weekend_price">
        <el-col :span="5">
          <el-input
            v-model="form.decimal_weekend_price"
            placeholder="请输入10-99999元"
          ></el-input
        ></el-col>
        <div class="help-block">
          周末价格 周末价：周五~周六（不包含国家法定节假日)
        </div>
      </el-form-item>
      <el-form-item label="节日价" prop="holiday_price">
        <el-col :span="5">
          <el-input
            v-model="form.decimal_holiday_price"
            placeholder="请输入10-99999元"
          ></el-input
        ></el-col>
        <div class="help-block">法定节假日的价格</div>
      </el-form-item>
      <el-form-item label="是否收取押金" prop="is_use_pledge">
        <el-switch v-model="form.is_use_pledge"></el-switch>
      </el-form-item>

      <el-form-item
        label="押金金额"
        prop="pledge_money"
        v-if="form.is_use_pledge"
      >
        <el-col :span="5">
          <el-input v-model="form.decimal_pledge_money" placeholder="请输入"></el-input>
        </el-col>
        <div class="help-block">线上收取押金金额</div>
      </el-form-item>

      <el-form-item label="是否允许加客" prop="is_allow_append">
        <el-switch v-model="form.is_allow_append"></el-switch>
      </el-form-item>

      <el-row>
        <el-col :span="5">
          <el-form-item
            label="加客人数"
            prop="append_num"
            v-if="form.is_allow_append"
          >
            <el-input v-model="form.append_num" placeholder="请输入"></el-input>
            <div class="help-block">最多可加人数</div>
          </el-form-item>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="5">
          <el-form-item
            label="加客费(人/晚)"
            prop="append_extra_money"
            v-if="form.is_allow_append"
          >
            <el-input
              v-model="form.decimal_append_extra_money"
              placeholder="请输入"
            ></el-input>
            <div class="help-block">超出宜住人数的额外房客费 人/晚</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">预定规则</el-divider>
      <el-form-item label="最晚预定时间" prop="last_book_time">
        <el-select v-model="form.last_book_time" placeholder="" clearable>
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="item in bookTimeOptions"
            :key="item.value"
          ></el-option>
        </el-select>
        <div class="help-block">当天可在此时间前预订当晚房源</div>
      </el-form-item>

      <el-form-item label="最少间夜数" prop="min_night_number">
        <el-col :span="5">
          <el-input v-model="form.min_night_number" placeholder=""></el-input>
        </el-col>
        <div class="help-block">最少间夜数</div>
      </el-form-item>

      <el-form-item label="日历时间" prop="open_month_number">
        <el-col :span="5">
          <el-select v-model="form.open_month_number" placeholder="">
            <el-option
              :label="item.text"
              :value="item.value"
              v-for="item in monthOptions"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <div class="help-block">将开放多久的日历时间</div>
      </el-form-item>

      <el-row>
        <el-col :span="6">
          <el-form-item label="最早入住时间" prop="start_checkin_time">
            <el-select v-model="form.start_checkin_time" placeholder="">
              <el-option
                :label="item.text"
                :value="item.value"
                v-for="item in inOptions"
                :key="item.value"
              ></el-option>
            </el-select>
            <div class="help-block">房客在此之前不可办理入住</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="最晚退房时间" prop="last_checkout_time">
            <el-select v-model="form.last_checkout_time" placeholder="">
              <el-option
                :label="item.text"
                :value="item.value"
                v-for="item in outOptions"
                :key="item.value"
              ></el-option>
            </el-select>
            <div class="help-block">房客需在此时间之前办理退房</div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="预定须知">
        <el-col :span="18">
          <el-input
            v-model="form.booking_inform"
            type="textarea"
            placeholder=""
            rows="5"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-divider content-position="left">为房客提供哪种退订规则</el-divider>

      <el-form-item label="退订规则" prop="unsubscribe_id">
        <el-radio-group v-model="form.unsubscribe_id">
          <div class="rule" v-for="item in unsubscribes" :key="item.id">
            <el-radio :label="item.id">
              {{ item.name }}
            </el-radio>
            <div class="help-radio">
              <p>
                入住{{
                  item.before_day > 0 ? "前" + item.before_day + "天" : "当天"
                }}{{ item.time }}前，可{{
                  item.refund_way_text
                }}，之后退订扣除{{ item.deduct_way_text }}
              </p>
            </div>
            <div class="rule-image">
              <el-image :src="item.pic_url"></el-image>
            </div>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </el-scrollbar>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      loading: false,
      saving: false,
      form: {},

      rules: {
        weekend_price: [
          {
            required: true,
            message: "周末价价必填",
            trigger: "blur",
          },
        ],
        normal_price: [
          {
            required: true,
            message: "平日价必填",
            trigger: "blur",
          },
        ],
        holiday_price: [
          {
            required: true,
            message: "节日价必填",
            trigger: "blur",
          },
        ],
        min_night_number: [
          {
            required: true,
            message: "最少夜间数必填",
            trigger: "blur",
          },
        ],
        open_month_number: [
          {
            required: true,
            message: "开放日历年限必选",
            trigger: "blur",
          },
        ],
        last_book_time: [
          {
            required: true,
            message: "最晚预定时间必选",
            trigger: "blur",
          },
        ],
        start_checkin_time: [
          {
            required: true,
            message: "入住时间必选",
            trigger: "blur",
          },
        ],
        last_checkout_time: [
          {
            required: true,
            message: "退房时间必选",
            trigger: "blur",
          },
        ],
        unsubscribe_id: [
          {
            required: true,
            message: "推定规则必选",
            trigger: "blur",
          },
        ],
      },
      //退订规则
      unsubscribes: [],
      //最晚预定时间选项
      bookTimeOptions: [],
      //最早入住时间选项
      inOptions: [],
      //最晚退房时间选项
      outOptions: [],
      monthOptions: [
        { text: "3个月", value: 3 },
        { text: "6个月", value: 6 },
        { text: "12个月", value: 12 },
      ],
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    //最晚预定时间选项
    for (let i = 18; i < 30; i++) {
      this.bookTimeOptions.push({
        text: `${i >= 24 ? "次日" + ("0" + (i - 24)) : i}:00前`,
        value: `${i >= 24 ? "0" + (i - 24) : i}:00`,
      });
    }
    //最早入住时间选项
    for (let i = 12; i < 20; i++) {
      this.inOptions.push({
        text: `${i}:00后`,
        value: `${i}:00`,
      });
    }
    //最晚退房时间选项
    for (let i = 0; i < 13; i++) {
      this.outOptions.push({
        text: `${i > 9 ? i : "0" + i}:00前`,
        value: `${i > 9 ? i : "0" + i}:00`,
      });
    }

    common_api.get_unsubscribe().then((res) => {
      if (res.code == 0) {
        this.unsubscribes = res.data;
      }
    });
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/house/edit/price", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("change", "price");
              }
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.rule {
  width: 100%;
  margin-bottom: 20px;
}
.help-radio {
  font-size: 12px;
  color: #606266;
}
.help-radio p {
  line-height: 20px;
  margin-top: 0;
  padding-left: 20px;
}
.rule-image .el-image {
  max-height: 100px;
  margin-left: 20px;
}
</style>