<!--
 * @Description: 单图上传，支持比例剪裁
 * @Author: 琢磨先生
 * @Date: 2022-06-11 16:44:51
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-07-26 11:59:11
-->


<template>
  <!-- <div class="btn_upload" @click="dialogVisible = true">
    <el-icon class="upload-icon"><Upload /></el-icon>
    <div class="text">上传图片</div>
  </div> -->
  <el-upload
    class="avatar-uploader"
    action=""
    :show-file-list="false"
    :http-request="upload"
    accept=".jpg,.jpeg,.png,.gif"
    :before-upload="beforeAvatarUpload"
  >
    <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
    <el-image
      v-if="imageUrl"
      :src="imageUrl"
      class="avatar"
      fit="fill"
    ></el-image>
    <el-icon v-else class="avatar-uploader-icon"><Upload /></el-icon>
    <div class="progress" v-if="saving">
      <el-progress type="circle" :width="90" :percentage="percentage" />
    </div>
  </el-upload>

  <el-dialog
    title="剪裁图片"
    v-model="visibleCropper"
    width="460px"
    :destroy-on-close="true"
  >
    <vue-cropper
      ref="cropper"
      :viewMode="1"
      :zoomable="false"
      :src="cropperBase64"
      :autoCropArea="1"
      :rotatable="false"
      :scalable="false"
      :minContainerHeight="400"
      :aspectRatio="aspectRatio"
      alt="Source Image"
    >
    </vue-cropper>
    <template #footer>
      <el-button type="primary" @click="handleCropper">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
// import ObsClient from "../../obs/esdk-obs-browserjs.3.22.3.min.js";
import ObsClient from "esdk-obs-browserjs/src/obs";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import img_utils from "@/utils/img_utils";
import md5_util from "@/utils/md5_util.js";

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      imageUrl: null,
      saving: false,
      visibleCropper: false,
      //需要剪裁的图片base64格式
      cropperBase64: "",
      //进度条
      percentage: 0,
    };
  },
  emits: ["close", "change"],
  props: ["folder", "aspectRatio", "hook", "src"],
  watch: {
    src: {
      handler() {
        //原始图片地址
        if (this.src) {
          this.imageUrl = this.src;
        }
      },
      immediate: true,
    },
  },
  created() {
    var settings = this.$store.getters.getSettings;
    this.bucketName = settings.obs_bucket_name;
    this.obs_cdn = settings.obs_cdn;
    this.obs = new ObsClient({
      access_key_id: settings.obs_key_id,
      secret_access_key: settings.obs_access_key,
      server: settings.obs_server,
      timeout: 60 * 5,
    });
  },
  methods: {
    /**
     * 文件上传之前
     */
    beforeAvatarUpload: async function (file) {
      if (this.aspectRatio && typeof this.aspectRatio == "number") {
        //如果存在要求需要剪裁
        var num = parseFloat(this.aspectRatio + "").toFixed(2);
        await new Promise((resolve) => {
          let img = new Image();
          img.onload = function () {
            file.width = img.width;
            file.height = img.height;
            resolve(file);
          };
          img.src = URL.createObjectURL(file);
        });
        var ratio = file.width / file.height;
        if (num != ratio.toFixed(2)) {
          //打开剪裁框
          this.openCropDialog(file);
          return false;
        }
      }
    },

    /**
     * 打开剪裁
     */
    openCropDialog(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.cropperBase64 = reader.result;
        //显示剪裁框
        this.visibleCropper = true;
      };
    },

    /**
     * 处理剪裁
     */
    handleCropper() {
      this.$refs.cropper
        .getCroppedCanvas({
          fillColor: "#fff",
        })
        .toBlob(async (blob) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            this.imageUrl = reader.result;
            //显示剪裁框
            this.visibleCropper = false;
            this.uploadObs(blob);
          };
        });
    },

    /**
     * 执行上传
     */
    upload(ctx) {
      let reader = new FileReader();
      reader.readAsDataURL(ctx.file);
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.uploadObs(ctx.file);
      };
    },

    /**
     * 执行上传图片至obs
     */
    uploadObs: async function (file) {
      var _f = file;
      //大于2M进行图片压缩
      if (file.size > 1024 * 1024 * 2) {
        _f = await img_utils.compress(file);
        _f.name = file.name;
      }
      this.saving = true;

      await this.handlerWH(file);

      var model = {
        height: file.height,
        width: file.width,
        size: file.size,
        psd_type: 1,
        name: file.name,
        ext: `.${_f.type.replace("image/", "")}`,
      };
      if (this.folder && this.folder.id) {
        model.father_id = this.folder.id;
      }

      //获取md5值
      var md5 = await md5_util.fileMd5(_f);
      model.md5 = md5;

      var res = await this.$http.get(`seller/v1/psd/file/dtl?md5=${md5}`);
      if (res.code == 0) {
        if (res.data) {
          this.saving = false;
          this.percentage = 100.0;
          this.$emit("change", res.data.file_url, this.hook);
          return;
        }
      }

      this.$http.get("/common/filename").then(async (res) => {
        if (res.code == 0) {
          if (!_f.name) {
            _f.name = res.data + model.ext;
            model.name = _f.name;
          }
          var result = await this.obs.putObject({
            Bucket: this.bucketName,
            Key: res.data + model.ext,
            SourceFile: _f,
            ProgressCallback: (transferredAmount, totalAmount) => {
              var percent = (transferredAmount * 100.0) / totalAmount;
              this.percentage = parseFloat(percent.toFixed(2));
            },
          });
          if (result.CommonMsg.Status < 300) {
            this.saving = false;
            var cdn_url = `${this.obs_cdn}/${res.data}${model.ext}`;
            model.file_url = cdn_url;
            this.$emit("change", cdn_url, this.hook);
            //存储图片到图片库
            this.$http.post("seller/v1/psd/file/add", model);
          }
        }
      });
    },
    /**
     * 处理图片的宽高
     */
    handlerWH(file) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          file.width = img.width;
          file.height = img.height;
          resolve(file);
        };
        img.src = URL.createObjectURL(file);
      });
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  position: relative;
}
.avatar-uploader .avatar {
  width: 120px;
  height: 120px;
}

.progress {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.avatar-uploader .el-progress__text {
  color: white;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  text-align: center;
}
</style>


